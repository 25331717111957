import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import Svg from 'react-inlinesvg';
import { TweenMax, Sine } from 'gsap';
import Lottie from 'react-lottie';
import Api from '../../utils/api';
import sendDataLayer from '../../utils/sendDataLayer';

import styles from '../../scss/components/connect.module.scss';
import SpotifyIcon from '../../images/svgs/icon_spotify.svg';
import desktop from '../../animationData/waves/wave-2-desktop.json';
import tablet from '../../animationData/waves/wave-2-tablet.json';
import mobile from '../../animationData/waves/wave-2-mobile.json';
import mobileLandscape from '../../animationData/waves/wave-2-mobile-landscape.json';

const ConnectIndex = ({ cms }) => {
  const [device, setDevice] = useState('desktop');

  const animationOptionsDesktop = {
    loop: true,
    autoplay: true,
    animationData: desktop,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };

  const animationOptionsTablet = {
    loop: true,
    autoplay: true,
    animationData: tablet,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };

  const animationOptionsMobile = {
    loop: true,
    autoplay: true,
    animationData: mobile,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };

  const animationOptionsMobileLandscape = {
    loop: true,
    autoplay: true,
    animationData: mobileLandscape,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };

  const connectToSpotify = () => {
    const connect = new Api({ url: 'connect' });
    localStorage.setItem('connected', 'true');
    sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Spotify Connect button');

    const params = {};

    if (process.env.NODE_ENV === 'development') {
      params.env = 'dev';
    }

    connect
      .submit(params)

      .then(response => {
        window.location.href = response.url;
      })
      .catch(error => {
        console.log(error);
      });
  };

  const goBack = () => {
    navigate('/');
    sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Go back Button Connect Page');
    localStorage.setItem('connectUrl', 'true');
  };

  const continueNologin = () => {
    localStorage.setItem('skip', 'true');
    localStorage.setItem('connected', 'false');
    navigate('/your-playlist');
    sendDataLayer('gtm.event', 'Interaction', 'Button Click', 'Continue without logging in button');
  };

  const deviceDetector = () => {
    if (window.innerWidth <= 600) {
      setDevice('mobile');
    } else if (window.innerHeight < window.innerWidth && window.innerWidth < 900) {
      setDevice('mobile-landscape');
    } else if (window.innerWidth > 601 && window.innerWidth < 1025) {
      setDevice('tablet');
    } else {
      setDevice('desktop');
    }
  };

  const handleClick = (event, link, label) => {
    event.preventDefault();
    sendDataLayer('gtm.event', 'Interaction', 'Button Click', `${label}`);
    window.open(`${link}`);
  };

  useEffect(() => {
    deviceDetector();
    window.addEventListener('resize', deviceDetector);

    return () => window.removeEventListener('resize', deviceDetector);
  });

  useEffect(() => {
    sendDataLayer('gtm.event', 'Pageview', 'Have an account?', '/connect');

    TweenMax.to('.connect', 1, {
      ease: Sine.easeInOut,
      opacity: 1,
      delay: 0,
    });
  }, []);

  return (
    <div className={`${styles.connect} connect wrapper color--tangerine text--center`}>
      <h1 className={`${styles.connect__title} push-v--mobile--x-small`}>{cms.connect.title}</h1>
      <p className={styles.connect__subtitle}>{cms.connect.subtitle}</p>
      <div className={styles.connect__buttons}>
        <button
          type="button"
          onClick={connectToSpotify}
          className={`${styles.connect__connectButton} button button--light button--icon push-v--mobile--small`}
        >
          {cms.connect.ctaText}
          <Svg src={SpotifyIcon} />
        </button>
        <button
          type="button"
          className={`${styles.connect__continueButton} cta cta--green`}
          onClick={continueNologin}
        >
          {cms.connect.noLoginCta}
        </button>
      </div>
      <div className={styles.connect__buttonWrapper}>
        <button type="button" className={`${styles.connect__cta} cta cta--green`} onClick={goBack}>
          Go back
        </button>
        <a
          className={`${styles.connect__cta} cta cta--green`}
          href="#"
          target="_blank"
          onClick={event => handleClick(event, 'https://www.spotify.com/uk/', 'Sign up button')}
        >
          Sign up
        </a>
      </div>
      <div className={styles.connect__lottieAnimation}>
        {device === 'desktop' && <Lottie options={animationOptionsDesktop} />}
        {device === 'tablet' && <Lottie options={animationOptionsTablet} />}
        {device === 'mobile' && <Lottie options={animationOptionsMobile} />}
        {device === 'mobile-landscape' && <Lottie options={animationOptionsMobileLandscape} />}
      </div>
    </div>
  );
};

export default ConnectIndex;
