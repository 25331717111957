import React, { useContext, useEffect } from 'react';
// import PropTypes from 'prop-types';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ConnectPage from '../components/Connect/index';
import cms from '../cms.json';

const SpotifyConnect = () => (
  <Layout>
    <SEO title="Spotify HIIT" pageUrl="/connect" twitterUsername="" />
    <ConnectPage cms={cms} />
  </Layout>
);

export default SpotifyConnect;
